import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { formatDistanceToNow } from 'date-fns';
import { vi } from 'date-fns/locale';

@Pipe({
  name: "PipeFormatDate",
})
export class PipeFormatDate implements PipeTransform {
  constructor(private datePipe: DatePipe) {}
  /**
   * @param date : new Date()
   * @param format 'YYYY-MM-dd' | 'dd-MM-YYYY' | 'MM/dd/YYYY' | 'YYYY-MM-dd HH:mm:ss' | 'HH:mm:ss' | 'shortDay' | 'longDay'
   * @returns trả về theo đúng định dạng đã truyền.
   */
  transform(
    dateInput: Date | string | null,
    format: "" | "YYYY-MM-dd" | "dd-MM-YYYY" | "MM/dd/YYYY" | "YYYY-MM-dd HH:mm:ss" | "HH:mm:ss" | "shortDay" | "longDay" | "HH:mm dd/MM/yyyy" | "HH:mm:ss | dd/MM/yyyy" | "pastTime",
    locale: Locale = vi
  ): string {

    if(dateInput == null || format == ""){
      return null;
    }

    const date = new Date(dateInput);
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    switch (format) {
      case "shortDay":
        return this.formatDate(date).shortDate;
      case "longDay":
        return this.formatDate(date).longDate;
      case "pastTime":
        return formatDistanceToNow(date, { addSuffix: true, locale: locale }).replace("khoảng ","");
      default:
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return this.datePipe.transform(formattedDate, format.replace("YYYY", "yyyy").replace("dd", "dd"));
    }
  }
  /**
   * format to display day in week
   * @param date: date
   */
  formatDate(date: Date) {
    let longDate = "";
    let shortDate = "";
    if (new Date(date).getDay() == 0) {
      longDate = "Chủ nhật";
      shortDate = "CN";
    } else if (new Date(date).getDay() + 1 == 1) {
      longDate = "Thứ 2";
      longDate = "T2";
    } else {
      longDate = "Thứ " + (new Date(date).getDay() + 1).toString();
      shortDate = "T" + (new Date(date).getDay() + 1).toString();
    }
    return { longDate, shortDate };
  }
}
