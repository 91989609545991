import { UserService } from '../shared/services/user.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserInterface } from '../shared/models/user.interface';
import { Subscription } from 'rxjs';
import { BaseService } from './../shared/services/base.service'
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit { 
  private subscription: Subscription;
  emptyLayout: boolean = true;
  errors: string;
  isRequesting: boolean;
  submitted: boolean = false;
  credentials: UserInterface = { emailOrPhone: '', password: '' };

  constructor(private userService: UserService, private router: Router, private activeRouter: ActivatedRoute, public baseService: BaseService) {
    this.baseService.fUseTemplate(activeRouter);
  }
  
  ngOnInit() {
    this.activeRouter.snapshot.params['dashboard'];
  }

  ngOnDestroy() {
    // prevent memory leak by unsubscribing
    //this.subscription.unsubscribe();
  }

  login({ value, valid }: { value: UserInterface, valid: boolean }) {
    this.submitted = true;
    this.isRequesting = true;
    this.errors = '';
    if (valid) {
      this.userService.login(value.emailOrPhone, value.password).finally(() => this.isRequesting = false).subscribe(
        result => {
          if (result) {
            this.router.navigate(['/bookings']);
          }
        },
        error => this.errors = error);
    }
  }

}
