import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
import { ConfigService } from '../utils/config.service';
import { map, catchError } from 'rxjs/operators';
import { Page } from '../../shared/models/paging/page';
import { PagedData } from '../../shared/models/paging/paged-data';
import { FilterPlacesInterface, PlacesInterface } from '../../shared/models/Places.interface';
import { AppAuthService } from '../services/roleService/auth.service';

@Injectable()

export class PlacesService extends BaseService {
  baseUrl: string = ''; 

  constructor(private http: HttpClient, private configService: ConfigService, private AuthService: AppAuthService) {
    super();

    this.baseUrl = configService.getApiURI();
  }

  public getAllplaces(page: Page, filter: any) { 
    const pagedData = new PagedData<FilterPlacesInterface>(); 
    let token = this.AuthService.getToken(); 
    var objFilter = {'paging.pageNumber': page.pageNumber.toString(), 'paging.pageSize': page.size.toString() };
    if (filter !== undefined) {
      if(filter.name){
        objFilter['name'] = filter.name ;
      }
      if(filter.description){
        objFilter['description'] = filter.description ;
      } 
      objFilter['status'] = filter.status;
    }; 
    const options = {
      headers: new HttpHeaders({
          'accept': 'application/json',
          'Content-Type': 'application/json-patch+json',
          "Authorization": "Bearer " + token
      }),
      params: new HttpParams({fromObject:objFilter}) 
  };  

    return this.http
      .get(
        this.baseUrl + '/erp/place/getallplace', options)
      .pipe(map((res : any)=> {
        pagedData.data = res.data;
        page.totalElements = res.total;
        page.totalPages = page.totalElements < page.size ? 1 : page.totalElements / page.size;
        pagedData.page = page;
        return pagedData;
      }));
       
  }

  public updatePlace(value: PlacesInterface) { 
    let token = this.AuthService.getToken(); 
    const options = {
      headers: new HttpHeaders({
          'accept': 'application/json',
          'Content-Type': 'application/json-patch+json',
          "Authorization": "Bearer " + token
      }) 
  };   
    if (value.tags) {
      value.tagsSelected = [];
      value.tags.forEach(element => {
        let selected = element.filter(
          item => item.selected).map(item => item.placeTagId);
        if (selected.length > 0) {
          value.tagsSelected = value.tagsSelected.concat(selected);
        }
      });
      value.tags = [];
      value.images = [];
    }
    return this.http.post(this.baseUrl + '/erp/place/updateplace?id=' + (value.id ? value.id : 0), value, options)
      .toPromise();
  }


  public insertPlace(place) { 
    let token = this.AuthService.getToken();
    const options = {
      headers: new HttpHeaders({
          'accept': 'application/json',
          'Content-Type': 'application/json-patch+json',
          "Authorization": "Bearer " + token
      }),
      params: new HttpParams({fromObject:place}) 
  };   
    return this.http
      .get(
        this.baseUrl + '/erp/place/insertplace', options)
        .pipe(map((res : any)=> {
        return res;
      }), catchError(this.handleError)); 
  }

  public updateStatus(id: string, status: string) { 
    let token = this.AuthService.getToken();
    const options = {
      headers: new HttpHeaders({
          'accept': 'application/json',
          'Content-Type': 'application/json-patch+json',
          "Authorization": "Bearer " + token
      }) 
  }; 
    return this.http.post(this.baseUrl + '/erp/place/updateStatusPlace', { Flag: status, Id: id }, options)
      .toPromise();
  }

  public InsertImages(obj: object) {
    let headers = new Headers();
    let token = this.AuthService.getToken();
    const options = {
      headers: new HttpHeaders({
          'accept': 'application/json',
          'Content-Type': 'application/json-patch+json',
          "Authorization": "Bearer " + token
      }) 
  }; 
    return this.http.post(this.baseUrl + '/erp/Hotel/insertImageUrl', { File: obj }, options).toPromise();
  }

  public LoadImages(id) { 
    let token = this.AuthService.getToken();
   
    var objFilter = { sectionId: id, projectName: 'place' };
    const options = {
      headers: new HttpHeaders({
          'accept': 'application/json',
          'Content-Type': 'application/json-patch+json',
          "Authorization": "Bearer " + token
      }),
      params: new HttpParams({fromObject: objFilter}) 
  };
    return this.http.get(this.baseUrl + '/erp/place/getImageUploadedPlace', options)
    .pipe(map((res : any)=>  {
        return res;
      }), catchError(this.handleError));
  }

  public LoadAvatarPlace(id) {
    let headers = new Headers();
    let token = this.AuthService.getToken(); 
    var objFilter = { id: id };
    const options = {
      headers: new HttpHeaders({
          'accept': 'application/json',
          'Content-Type': 'application/json-patch+json',
          "Authorization": "Bearer " + token
      }),
      params: new HttpParams({fromObject: objFilter}) 
  };
    return this.http.get(this.baseUrl + '/erp/place/getAvatarPlace', options)
      .pipe(map(res => {
        return res;
      }), catchError(this.handleError));
  }

  public DeleteImages(id, listImages) { 
    let token = this.AuthService.getToken();
    const options = {
      headers: new HttpHeaders({
          'accept': 'application/json',
          'Content-Type': 'application/json-patch+json',
          "Authorization": "Bearer " + token
      })
     }; 
    return this.http.post(this.baseUrl + '/erp/place/deleteImagePlace', { Id: id, Images: listImages }, options).toPromise();
  }


  public SetAvatarPlace(imageId, placeId) { 
    const token = this.AuthService.getToken(); 
    const options = {
      headers: new HttpHeaders({
          'accept': 'application/json',
          'Content-Type': 'application/json-patch+json',
          "Authorization": "Bearer " + token
      })
     };
    return this.http.post(this.baseUrl + '/erp/place/setAvatarPlace', { ImageId: imageId, placeId: placeId }, options).toPromise();
  }

  public SortImagesPlace(listImages, Id) { 
    const token = this.AuthService.getToken();
    const options = {
      headers: new HttpHeaders({
          'accept': 'application/json',
          'Content-Type': 'application/json-patch+json',
          "Authorization": "Bearer " + token
      })
     };
    Id = Id === undefined ? 0 : Id;
    return this.http.post(this.baseUrl + '/erp/place/sortImagesPlace', { Images: listImages, PlaceId: Id }, options).toPromise();
  }
  public getListsTagsByPlaceId(id: number) {
    const options = {
      headers: new HttpHeaders({
          'accept': 'application/json',
          'Content-Type': 'application/json-patch+json' 
      })
     };
    return this.http.get(this.baseUrl + '/erp/place/GetTagsByPlaceId?id=' + (id === undefined ? 0 : id), options)
      .toPromise();
  }

}
