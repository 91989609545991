import { Observable } from 'rxjs/Rx';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Injectable()
export class BaseService {
  public useTemplate: boolean = true;
  constructor() {

  }
  fUseTemplate(activeRoute: ActivatedRoute): void {
    if (activeRoute.data['value'] != undefined && activeRoute.data['value'].useTemplate != undefined) {
      this.useTemplate = activeRoute.data['value'].useTemplate;
    }
  }
  protected handleError(error: any) {
    var applicationError = error.headers.get('Application-Error');

    // either applicationError in header or model error in body
    if (applicationError) {
      return Observable.throw(applicationError);
    }

    var modelStateErrors: string = '';
    var serverError = error.json();

    if (!serverError.type) {
      for (var key in serverError) {
        if (serverError[key])
          modelStateErrors += serverError[key] + '\n';
      }
    }

    modelStateErrors = modelStateErrors == "" ? null : modelStateErrors;
    return Observable.throw(modelStateErrors || 'Server error');
  }
}
