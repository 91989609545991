import { Injectable } from '@angular/core';

const LINK_TYPE = 'link';

interface MenuItem {
  state: string;
  type: string;
  name: string;
  icon: string;
  filters: string[];
}

/**
 * 
 * @param state 
 * @param name Tên menu
 * @param icon code icon
 * @param filters tên gợi ý search menu
 * @returns 
 */
const createMenuItem = (
  state: string,
  name: string,
  icon: string,
  filters: string[]
): MenuItem => ({
  state,
  type: LINK_TYPE,
  name,
  icon,
  filters,
});

const MENUITEMS: MenuItem[] = [
  createMenuItem('bookings', 'Bookings', 'receipt_long', ['bookings']),
  createMenuItem('accounts', 'Accounts', 'person_outline', ['accounts']),
  createMenuItem('contacts', 'Contacts', 'contacts', ['contacts']),
  createMenuItem('accounting', 'Accounting', 'person_outline', ['accounting']),
  createMenuItem('bookingsmanagement', 'CS Follow', 'library_books', ['bookingsmanagement', 'cs', 'follow', 'cs follow']),
  createMenuItem('supportrequests', 'Support Requests', 'support_agent', ['supportrequests']),
  createMenuItem('followinvoice', 'CS Follow Invoice', 'money_off_csred', ['invoice', 'follow invoice', 'follow']),
  createMenuItem('topupmanagement', 'Topup Management', 'move_to_inbox', ['topupmanagement']),
  createMenuItem('bookingshotel', 'Confirm Hotel / Tour', 'domain', ['bookingshotel']),
  createMenuItem('issueinvoice', 'Issue Invoice', 'document_scanner', ['issueinvoice']),
  createMenuItem('dashboardV2', 'Dashboard', 'dashboard', ['dashboard']),
  createMenuItem('hotel-management', 'Hotel Management', 'synchronize', ['hotel-management']),
  createMenuItem('bookforagent', 'Book Offline', 'book_online', ['bookforagent', 'off', 'booking off']),
  createMenuItem('waits-to-ask', 'Booking OR', 'book_waits', ['waits-to-ask', 'booking', 'or', 'booking or', 'on request']),
  // createMenuItem('bookingofflight', 'Booking OFF', 'speaker_notes_off', ['bookingofflight']),
  createMenuItem('log', 'Log', 'insert_drive_file', ['log']),
  createMenuItem('pushnotification', 'Push Notification', 'notifications', ['pushnotification']),
  createMenuItem('bookingwithluggages', 'Bookings & Luggage', 'business_center', ['bookingwithluggages']),
];

@Injectable()
export class MenuItems {
  getMenuitem(): MenuItem[] {
    return MENUITEMS;
  }
}
