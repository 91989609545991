import { Injectable } from '@angular/core';

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
import { ConfigService } from '../utils/config.service';
import { AppAuthService } from '../services/roleService/auth.service';
import { map, catchError } from 'rxjs/operators';
@Injectable()

export class UploadService extends BaseService {
  baseUrl: string = '';

  constructor(private http: HttpClient, private configService: ConfigService, private AuthService: AppAuthService) {
    super();

    this.baseUrl = configService.getCdn1ApiURI();
  }

  upload(files, parameters) {
    let token = this.AuthService.getToken();
    const options = {
      headers: new HttpHeaders({
        'accept': 'application/json',
        'Content-Type': 'application/json-patch+json',
        "Authorization": "Bearer " + token
      }),
      params: new HttpParams()
    };

    options.params = parameters;
    return this.http.post(this.baseUrl + '/Upload/UploadImagesTinyEditor', files, options)
      .pipe(map((res: any) => {
        return res;
      }), catchError(this.handleError))
  }
}
