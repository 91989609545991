import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ImagesService, ImageModel } from '../services/images.service';
import { MatSnackBar } from '@angular/material';

@Injectable()
export class ImageFile {

  errors: any;
  AvatarPlace: any;
  lstImages = [];
  data = new ImageModel();
  constructor(private mService: ImagesService, public snackBar: MatSnackBar) {
  }

  // lấy ds hình theo placeId
  getImages(data: ImageModel) {
    this.mService.LoadImages(data)
      .subscribe(res => {
        this.lstImages = res;
        this.mService.LoadAvatarPlace(data.body.pid)
          .subscribe(res2 => {
            this.AvatarPlace = res2;
            if (this.lstImages.length > 0 && (this.AvatarPlace === undefined || this.AvatarPlace === '')) {
              // this.SetAvatar(this.lstImages[0].id, data.body.rowId);
              this.SetAvatar(data);
            }
          }, error => {
            this.errors = error;
          });
        return this.lstImages;
      }, error => {
        this.errors = error;
      });
  }

  // set avatar
  SetAvatar(data: ImageModel) {
    this.mService.SetAvatarPlace(data.body.id, data.body.pid).then(response => {
      this.SortImagesPlace(data);
      this.snackBar.open('Thay đổi thứ tự thành công!', '✔', { duration: 2000, });
    }).catch(exp => {
      this.errors = exp;
      this.snackBar.open('Thay đổi thứ tự thành công!', '✔', { duration: 2000, });
      // this.snackBar.open('Thay đổi thứ tự thất bại!', '✕', { duration: 2000, });
    });
  }

  // sắp xếp ds hình
  SortImagesPlace(data: ImageModel) {
    data.body = {
      Images: this.lstImages,
      Id: data.body.placeId === undefined ? 0 : data.body.placeId,
    };
    this.mService.SortImagesPlace(data).then(response => {
      this.getImages(data);
    }).catch(exp => {
      this.errors = exp;
    });
  }

  // xóa hình
  DeleteImage(data: ImageModel) {
    this.mService.DeleteImages(data.body.Id, this.lstImages).then(response => {
      this.getImages(data);
      this.snackBar.open('Delete Image Success!', '✔', { duration: 2000, });
    }).catch(error => {
      this.errors = error;
      this.snackBar.open('Delete Image Fail!', '✕', { duration: 2000, });
    });
  }
  InsertImages(data: ImageModel) {
    this.mService.InsertImages(data).then(Response => {
      this.getImages(this.data.body.sectionId);
    }).catch(exp => {
    });
  }
}

export class FileInfo {
  caption: string;
  createBy: string;
  created: Date;
  fileName: string;
  fileSize: number;
  fileUrl: string;
  flag: string;
  id: number;
  modifyBy: string;
  modifyDate: Date;
  projectId: number;
  sectionId: number;
  sortOrder: number;
  thumbUrl: string;
}
