import { MediaMatcher } from "@angular/cdk/layout";
import { ChangeDetectorRef, Component } from "@angular/core";
import { MenuItems } from "../../../shared/menu-items/menu-items";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class AppSidebarComponent {
  mobileQuery: MediaQueryList;
  menuSearch: string;

  private _mobileQueryListener: () => void;
  isAcount: boolean;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, public menuItems: MenuItems) {
    this.mobileQuery = media.matchMedia("(min-width: 768px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    //this.mobileQuery.addListener(this._mobileQueryListener);
    console.log(this.menuItems.getMenuitem());
    let email = localStorage.getItem("email");
    console.log("email", localStorage.getItem("email"));
    for (let index = 0; index < this.menuItems.getMenuitem().length; index++) {
      if (email && email.trim() != "hoa.nguyen@tmgroup.vn") {
        if (this.menuItems.getMenuitem()[index].name == "Log") {
          this.menuItems.getMenuitem().splice(index, 1);
          index--;
        } else if (this.menuItems.getMenuitem()[index].name == "Booking OFF") {
          this.menuItems.getMenuitem().splice(index, 1);
          index--;
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  public menuAction: any[] = this.menuItems.getMenuitem();
  checkAccount() {
    let count = 0;
    let email = localStorage.getItem("email");
    // this.mService.GetUserAdminCRMMenu().then((data) => {
    //   if (data) {
    //     let arr: any = data;
    //     for (let i = 0; i < arr.length; i++) {
    //       if (data[i].trim() == email.trim()) {
    //         count++;
    //       }
    //     }
    //     if (count > 0) {
    //       this.isAcount = false;
    //     } else {
    //       this.isAcount = true;
    //     }
    //   }
    // });
  }
  SearchMenu($event) {
    if ($event.target.value) {
      this.menuAction = this.menuItems.getMenuitem().filter((x) => x.filters.indexOf($event.target.value) >= 0);
    } else {
      this.menuAction = this.menuItems.getMenuitem();
    }
  }
}
