import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs/Rx';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { IPlaceRegion } from './../../shared/models/Places-region.interface'; 
import {ConfigService} from './../utils/config.service'; 
import { AppAuthService } from '../services/roleService/auth.service';
@Injectable()
export class PlaceRegionService {
  placeRegionList: IPlaceRegion[];
  baseUrl: string = '';  
    constructor(private http: HttpClient,private configService: ConfigService, private AuthService: AppAuthService) { 
      this.baseUrl = configService.getApiURI();
    }

  public getListsByPlaceId(id: number) {
    let token = this.AuthService.getToken();
    let  options = {
    headers: new HttpHeaders({
      'accept': 'application/json',
      'Content-Type': 'application/json-patch+json',
      "Authorization": "Bearer " + token
    }) 
    };  
    return this.http.get(this.baseUrl + '/erp/place/ListRegionById?id=' + id, options)
        .toPromise();
  } 
  public addNewPlaceRegion(_cat: IPlaceRegion) {  
    let token = this.AuthService.getToken();
    let  options = {
    headers: new HttpHeaders({
      'accept': 'application/json',
      'Content-Type': 'application/json-patch+json',
      "Authorization": "Bearer " + token
    }) 
    }; 
    return this.http.post(this.baseUrl + '/erp/place/CreateNewPlaceRegion', _cat, options)
       .toPromise();
  } 
  public updatePlaceRegion(_cat: IPlaceRegion) {
    let token = this.AuthService.getToken();
    let  options = {
    headers: new HttpHeaders({
      'accept': 'application/json',
      'Content-Type': 'application/json-patch+json',
      "Authorization": "Bearer " + token
    }) 
    }; 
    return this.http.post(this.baseUrl + '/erp/place/UpdatePlaceRegion?id=' + (_cat.id?_cat.id:0), _cat, options)
        .toPromise();
  }
   
  public deleteProduct(id: number) {
    let token = this.AuthService.getToken();
    let  options = {
    headers: new HttpHeaders({
      'accept': 'application/json',
      'Content-Type': 'application/json-patch+json',
      "Authorization": "Bearer " + token
    }) 
    }; 
    return this.http.post(this.baseUrl + '/erp/place/DeletePlaceRegion?id=' + id, options)
        .toPromise();    
  }

   public getListsSubRegionByRegionId(id: number) {
    let token = this.AuthService.getToken();
    let  options = {
    headers: new HttpHeaders({
      'accept': 'application/json',
      'Content-Type': 'application/json-patch+json',
      "Authorization": "Bearer " + token
    }) 
    }; 
    return this.http.get(this.baseUrl + '/erp/place/GetSubRegionByRegionId?id=' + id, options)
        .toPromise();
  }  
}
