import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class ConfigService {

  _apiURI: string;
  _apiURIFlight: string;
  _hostDomain: string;
  _loginDomain: string;
  _cookieDomain: string;
  _cdn1ApiURI: string;
  _cdn1Domain: string;
  _apiAvatarFolder: string;
  _webDomain: string;
  _configSever: string;

  _apiMenuData: string;
  // _apiSentEmail: string;
  _apiCRMBooking: string;
  _apiNotify: string;
  _apiSyncHotel: string;
  constructor() {
    if (environment.production) {
      // live
      this.setConnectLive();
      this.setDataLive();


      // beta
      // this.setConnectBeta();
      // this.setDataBeta();
    } else {
      // Live
      this.setConnectLive();
      this.setDataLive();

      // Beta  
      // this.setConnectBeta();
      //  this.setDataBeta();

      // Local
      // this.setConnectLocal();
      // this.setDataLocal();
    }
    this._cdn1Domain = 'https://cdn1.ivivu.com/newcdn';
    this._apiURI = this._hostDomain + '/api';
    this._cdn1ApiURI = this._cdn1Domain + '/api';
    this._apiAvatarFolder = this._hostDomain + '/upload/avatar';
  }

  // set url
  setConnectLive() {
    this._apiURIFlight = 'https://api-flight.ivivu.com';
    this._hostDomain = 'https://gw.plutos.vn';
    this._cookieDomain = '.crm.plutos.vn';

    // this._hostDomain = 'http://localhost:63563';
      // this._cookieDomain = 'localhost';

    this._loginDomain = 'https://gate.ivivu.com';
    this._webDomain = 'https://web.plutos.vn';
    // this._webDomain = 'http://localhost:8100/';

    this._apiNotify = 'https://gate.ivivu.com';
    this._apiSyncHotel = 'https://svc1.ivivu.com';
  }
  setConnectBeta() {

    this._apiURIFlight = 'https://beta-air.ivivu.com';
    this._hostDomain = 'https://vvsapi-beta.ivivu.com';
    // this._hostDomain = 'http://localhost:63563';
    this._cookieDomain = '.ivivu.com';
    this._loginDomain = 'https://beta-olivia.ivivu.com';

    this._webDomain = 'https://beta-vvs.ivivu.com';
    // this._webDomain = 'http://localhost:8100';

    this._apiNotify = 'https://beta-olivia.ivivu.com';
    this._apiSyncHotel = 'https://svc1-beta.ivivu.com';
  }
  setConnectLocal() {
    // this._configSever =
    this._apiURIFlight = 'https://beta-air.ivivu.com';
    this._hostDomain = 'http://localhost:63563';
    // this._hostDomain = 'https://vvsapi-beta.ivivu.com';

    this._cookieDomain = 'localhost';
    this._loginDomain = 'https://beta-olivia.ivivu.com';
    // this._webDomain = 'https://beta-vvs.ivivu.com';
    this._webDomain = 'http://localhost:8001';

    this._apiNotify = 'https://beta-olivia.ivivu.com';
    this._apiSyncHotel = 'https://svc1-beta.ivivu.com';
  }
  setDataLive() {
    // CRM
    this._apiCRMBooking = 'https://svc3.ivivu.com';
  }
  setDataBeta() {
    // CRM
    this._apiCRMBooking = 'https://beta-svc3.ivivu.com';
  }
  setDataLocal() {
    // CRM
    this._apiCRMBooking = 'http://localhost:12950';
  }
  getApiURIFlight() {
    return this._apiURIFlight;
  }
  getApiURI() {
    return this._apiURI;
  }
  getLoginUrl() {
    return this._loginDomain;
  }
  getCdn1ApiURI() {
    return this._cdn1ApiURI;
  }
  getAvartarFolder() {
    return this._apiAvatarFolder;
  }
  getCookieDomain() {
    return this._cookieDomain;
  }
  getWebDomain() {
    return this._webDomain;
  }

  getApiCRMBooking() {
    return this._apiCRMBooking + '/api';
  }

  getNotify() {
    return this._apiNotify + '/mobile';
  }
  getXMLHotel() {
    return this._apiSyncHotel;
  }

}
