import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { AppAuthService } from './auth.service';
import * as decode from 'jwt-decode'

@Injectable()

export class RoleGuardService implements CanActivate {

  constructor(private authService: AppAuthService, public router: Router,) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    // this will be passed from the route config
    // on the data property
    const expectedRole = route.data.roles;
    const token = this.authService.getToken();
    // decode the token to get its payload
    if(!token)
    {
     //  this.router.navigate(['login']);
       this.router.navigate(['/login'], {
        queryParams: {
          return: state.url
        }
      })
    return false;

    }
    const tokenPayload = decode(token);
    var checkRole  = false;
    var userRoles =[];
    if(typeof tokenPayload.role ==='string')
    {
      userRoles.push(tokenPayload.role);
    }
    else{
      userRoles =tokenPayload.role;
    }
    expectedRole.forEach(element => {
      if(userRoles.some(e => e === element))
      {
        checkRole = true;
        return;
      } 
    });
    if (
      !this.authService.isAuthenticated() || !checkRole
    ) {
      this.router.navigate(['/login'], {
        queryParams: {
          return: state.url
        }
      })
     // this.router.navigate(['login']);
      return false;
    }
    return true;
  }

}
