import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
import { ConfigService } from '../utils/config.service'; 
import { map, catchError } from 'rxjs/operators';
import { Page } from '../models/paging/page';
import { PagedData } from '../models/paging/paged-data';
import { AppAuthService } from './roleService/auth.service'; 


@Injectable()

export class ImagesService extends BaseService {
  baseUrl = '';
   
  // tslint:disable-next-line: deprecation
  headers = new Headers();
  token: any;
  // tslint:disable-next-line: deprecation
  constructor(private http: HttpClient, private configService: ConfigService, private AuthService: AppAuthService) {
    super();

    this.baseUrl = configService.getApiURI();
    // config
    this.token = this.AuthService.getToken(); 
  }


  // public InsertImages(obj: object) {
  //   return this.http.post(this.baseUrl + '/erp/menu/insertImageUrl', { File: obj }, { headers }).toPromise();
  // }

  public InsertImages(data: ImageModel) {
    const body = JSON.stringify(data);
    const options = {
      headers: new HttpHeaders({
          'accept': 'application/json',
          'Content-Type': 'application/json-patch+json',
          "Authorization": "Bearer " + this.token
      }) 
  }; 
    return this.http.post(this.baseUrl + '/erp/' + data.controller + '/insertImageUrl', body, options).toPromise();
  }
 
  public LoadImages(data: ImageModel) {
    const body = JSON.stringify(data);
    const options = {
      headers: new HttpHeaders({
          'accept': 'application/json',
          'Content-Type': 'application/json-patch+json',
          "Authorization": "Bearer " + this.token
      }) 
  }; 
    return this.http.post(
      this.baseUrl + '/erp/' + data.controller + '/getImageUploadedPlace', body, options)
      .pipe(map((res : any)=> {
        return res;
      }), catchError(this.handleError)); 
       
  }
  public LoadAvatarPlace(id) {
    const objFilter = { id: id };
    const options = {
      headers: new HttpHeaders({
          'accept': 'application/json',
          'Content-Type': 'application/json-patch+json',
          "Authorization": "Bearer " + this.token
      }),
      params: new HttpParams({fromObject:objFilter}) 
  }; 
   
    return this.http.get(this.baseUrl + '/erp/menu/getAvatarPlace', options)
    .pipe(map((res : any)=> {
      return res;
    }), catchError(this.handleError)); 
  }
 

  public DeleteImages(id, listImages) {
    const options = {
      headers: new HttpHeaders({
          'accept': 'application/json',
          'Content-Type': 'application/json-patch+json',
          "Authorization": "Bearer " + this.token
      }) 
  }; 
    return this.http.post(this.baseUrl + '/erp/menu/deleteImagePlace', { Id: id, Images: listImages }, options).toPromise();
  }

  // public DeleteImages(data: ImageModel) {
  //   const body = JSON.stringify(data);
  //   return this.http.post(this.baseUrl + '/erp/menu/deleteImagePlace', body, { headers: headers }).toPromise();
  // }


  public SetAvatarPlace(imageId, placeId) {
    const options = {
      headers: new HttpHeaders({
          'accept': 'application/json',
          'Content-Type': 'application/json-patch+json',
          "Authorization": "Bearer " + this.token
      }) 
  }; 
    return this.http.post(this.baseUrl + '/erp/menu/setAvatarPlace', { ImageId: imageId, placeId: placeId },options).toPromise();
  }
  // public SetAvatarMenu(data: ImageModel) {
  //   const body = JSON.stringify(data);
  //   return this.http.post(this.baseUrl + '/erp/menu/setAvatarMenu', body, { headers }).toPromise();
  // }

  // public SortImagesPlace(listImages, Id) {
  //   Id = Id === undefined ? 0 : Id;
  //   return this.http.post(this.baseUrl + '/erp/menu/sortImagesPlace', { Images: listImages, PlaceId: Id }, { headers }).toPromise();
  // }
  public SortImagesPlace(data: ImageModel) {
    const body = JSON.stringify(data);
    const options = {
      headers: new HttpHeaders({
          'accept': 'application/json',
          'Content-Type': 'application/json-patch+json',
          "Authorization": "Bearer " + this.token
      }) 
  }; 
    return this.http.post(this.baseUrl + '/erp/' + data.controller + '/sortImagesPlace', body, options).toPromise();
  }
  public getListsTagsByPlaceId(id: number) {
    const options = {
      headers: new HttpHeaders({
          'accept': 'application/json',
          'Content-Type': 'application/json-patch+json',
          "Authorization": "Bearer " + this.token
      }) 
  }; 
    return this.http.get(this.baseUrl + '/erp/menu/GetTagsByPlaceId?id=' + (id === undefined ? 0 : id),options)
      .toPromise();
  }
  public getRecipeByMenuId(data: any) {
    const body = JSON.stringify(data);
    const options = {
      headers: new HttpHeaders({
          'accept': 'application/json',
          'Content-Type': 'application/json-patch+json',
          "Authorization": "Bearer " + this.token
      }) 
  }; 
    return this.http.post(
      this.baseUrl + '/erp/menu/GetRecipeByMenuId', body, options)
      .pipe(map((res : any)=> {
        return res.list;
      }), catchError(this.handleError));  
  }
}

export class ImageModel {
  controller: string;
  body: any;
}
