import { Injectable } from "@angular/core";

import { saveAs } from "file-saver";
import { UtilityService } from "../common/utilities";
import { ConfigService } from "../utils/config.service";
import { BaseService } from "./base.service";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable()
export class DownloadService extends BaseService {
  baseUrl: string = "";

  public startLoading = new BehaviorSubject<boolean>(false);
  public isLoading = this.startLoading.asObservable();

  constructor(private configService: ConfigService, private utilities: UtilityService) {
    super();

    this.baseUrl = this.configService.getCdn1ApiURI();
  }

  /**
   * Lưu file 
   * @param codeBase 
   * @param fileName 
   */
  download(codeBase: string, fileName: string, typeFile: "xlsx") {
    var dataBlob = this.utilities.b64toBlob(codeBase, `application/${typeFile}`);
    saveAs(dataBlob, fileName);
  }
}
