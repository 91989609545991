import { Injectable, Inject } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ConfigService } from '../../utils/config.service';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable()

export class AppAuthService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public jwtHelper: JwtHelperService,
    private configService: ConfigService,
  ) { }
  setToken(token: string) {
    if (isPlatformServer(this.platformId))
      return;
    var expires = '; max-age=' + 10 * 24 * 60 * 60;
    document.cookie = 'authtoken=' + token + expires + ';path=/;domain=' + this.configService.getCookieDomain();
  }

  getToken() {
    var data = this.getCookie('authtoken');
    if (data && data != 'null')
      return data;
    else {
      this.setCookie('authtoken', '', -1);
      return '';
    }
  }

  public isAuthenticated(): boolean {
    const token = this.getToken();
    if (token) {
      var log = !this.jwtHelper.isTokenExpired(token);

      return log;
    }
    else return false;
  }

  logout() {
    if (isPlatformServer(this.platformId))
      return;

    document.cookie = 'authtoken=;path=/;domain=' + this.configService.getCookieDomain();
    //window.location.href = 'https://ivivu.com';
  }
  setCookie(name, value, days) {
    if (isPlatformServer(this.platformId))
      return;

    if (days) {
      var date = new Date();
      var expiresP = '; max-age=' + days * 24 * 60 * 60;
      var expires = '; expires=' + expiresP;
    } else {
      expires = '';
    }
    document.cookie = name + '=' + encodeURIComponent(JSON.stringify(value)) + expires + ';path=/;domain=' + this.configService.getCookieDomain();
  }
  getCookie(cname) {
    if (isPlatformServer(this.platformId))
      return;

    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

}
