import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { FullComponent } from './layouts/full/full.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { MainMaterialModule } from './main-material-module';
import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';

// User module, permission
import { DatePipe } from '@angular/common';
import {
  MAT_DATE_LOCALE,
  MatDatepickerModule,
  MatNativeDateModule
} from "@angular/material";
import { MatMenuModule } from '@angular/material/menu';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { MatTimepickerModule } from 'mat-timepicker';
import { ThemeService } from 'ng2-charts';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BaseCRMService } from '../app/shared/services/basecrm.service';
import { LoginComponent } from './login/login.component';
import { ImageFile } from './shared/common/ImageFile';
import { UtilityService } from './shared/common/utilities';
import { ProcessSpinnerComponent } from './shared/process-spinner/process-spinner.component';
import { BaseService } from './shared/services/base.service';
import { DialogDataService } from './shared/services/dialogData.service';
import { ImagesService } from './shared/services/images.service';
import { PlaceRegionService } from './shared/services/place-region.service';
import { PlacesService } from './shared/services/places.service';
import { AppAuthService } from './shared/services/roleService/auth.service';
import { RoleGuardService } from './shared/services/roleService/role.guard';
import { UploadService } from './shared/services/upload.service';
import { UserService } from './shared/services/user.service';
import { ConfigService } from './shared/utils/config.service';
import { PipeFormatDate } from './shared/pipes/date-format.pipe';
import { PipeFormaCurrency } from './shared/pipes/currency-format.pipe';
import { DownloadService } from './shared/services/download.service';

export function jwtOptionsFactory(AuthService) {
  return AuthService.getToken();
}
export function jwtTokenGetter() {
  return jwtOptionsFactory(AppAuthService);
}

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppHeaderComponent,
    SpinnerComponent,
    AppSidebarComponent,
    LoginComponent,
    ProcessSpinnerComponent
  ],
  entryComponents: [ProcessSpinnerComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MainMaterialModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    SharedModule,
    MatDatepickerModule,
    MatNativeDateModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter
      }
    }),
    RouterModule.forRoot(AppRoutes),
    NgxSpinnerModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTimepickerModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    BaseService,
    UserService,
    ConfigService,
    RoleGuardService,
    AppAuthService,
    JwtHelperService,
    UploadService,
    PlacesService,
    BaseCRMService,
    PlaceRegionService,
    ImagesService,
    ImageFile,
    DialogDataService,
    ThemeService,
    UtilityService,
    DatePipe,
    PipeFormaCurrency, PipeFormatDate,
    DownloadService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
