import { EventEmitter, Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { ProcessSpinnerComponent } from '../../shared/process-spinner/process-spinner.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Event, NavigationEnd, Router } from '@angular/router';

@Injectable()
export class DialogDataService extends BaseService {
  dialogData: any;
  activitiesData: any;
  // isExport = false;
  constructor(private dialog: MatDialog, private router: Router, ) {
    super();
    // this.showProgressSpinnerUntilExecuted(new Observable(this.myObservable));
  }

  GetDialogData() { return this.dialogData; }
  UpdateDialogData(dataUpdate: any) {
     this.dialogData = dataUpdate; 
    }

  myObservable(observer) {
    setTimeout(() => {
      observer.next('done waiting for 5 sec');
      observer.complete();
    }, 10000);
  }

  showProgressSpinnerUntilExecuted(/*observable: Observable<Object>*/) {
    const observable = new Observable<Object>(this.myObservable);
    const dialogRef: MatDialogRef<ProcessSpinnerComponent> = this.dialog.open(ProcessSpinnerComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    const subscription = observable.subscribe(
      (response: any) => {
        subscription.unsubscribe();
        // handle response
        console.log(response);
        dialogRef.close();
      },
      (error) => {
        subscription.unsubscribe();
        // handle error
        dialogRef.close();
      }
    );
  }
  start(message?): MatDialogRef<ProcessSpinnerComponent> {
    const dialogRef = this.dialog.open(ProcessSpinnerComponent, {
      disableClose: true,
      data: message === '' || message === undefined ? 'Loading...' : message
    });
    return dialogRef;
  }

  stop(ref: MatDialogRef<ProcessSpinnerComponent>) {
    ref.close();
  }
}
