import { Injectable } from "@angular/core";
import { catchError, map } from "rxjs/operators";
import { BaseService } from "./base.service";

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { ConfigService } from "../utils/config.service";

import { Page } from "../models/paging/page";
import { PagedData } from "../models/paging/paged-data";
import { AppAuthService } from "./roleService/auth.service";
import { UserService } from "./user.service";
import { FilterInterface } from "../models/Filter.interface";
import { InfoTax, ResponseApi } from "../models/bookings.interface";

@Injectable()
export class BaseCRMService extends BaseService {
  baseUrl = "";
  public pageName = "";
  userInfo: any;
  constructor(
    private router: Router,
    private http: HttpClient,
    private configService: ConfigService,
    private AuthService: AppAuthService,
    private uService: UserService
  ) {
    super();
    this.baseUrl = configService.getApiURI();
    this.userInfo = this.uService.userInfo;
  }

  public GetAllDataPageMain(page: Page, filter: any, id = 0) {
    const pagedData = new PagedData<any>();
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    filter.pageIndex = page.pageNumber;
    filter.pageSize = page.size;
    const body = JSON.stringify({ PageName: this.pageName, Body: JSON.stringify(filter) });
    let url = this.baseUrl + "/CrmData/GetCrmData";
    if (id != 0) url = this.baseUrl + "/CrmData/GetCrmData?Id=" + id;
    return this.http
      .post(url, body, options)
      .pipe(
        map((res: any) => {
          pagedData.data = res.data;
          page.totalElements = res.total;
          page.totalPages = page.totalElements < page.size ? 1 : page.totalElements / page.size;
          pagedData.page = page;
          pagedData.additionalData = res.additionalData;
          return pagedData;
        })
      )
      .toPromise();
  }

  public GetData(filter: any) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    const body = JSON.stringify({ PageName: this.pageName, Body: JSON.stringify(filter) });
    return this.http
      .post(this.baseUrl + "/CrmData/GetCrmData", body, options)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .toPromise();
  }

  public GetDataV2(page: Page, filter: any) {
    // const options = {
    //   headers: new HttpHeaders({
    //     'accept': 'application/json',
    //     'Content-Type': 'application/json-patch+json',
    //     'Authorization': 'Bearer ' + this.AuthService.getToken()
    //   }),
    // };
    // const body = JSON.stringify({ PageName: this.pageName, Body: JSON.stringify(filter) });
    // return this.http.post(this.baseUrl + '/CrmData/GetCrmData', body, options)
    //   .pipe(map((res: any) => {
    //     return res;
    //   }))
    //   .toPromise();
    const pagedData = new PagedData<any>();
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    filter.pageIndex = page.pageNumber;
    filter.pageSize = page.size;
    const body = JSON.stringify({ PageName: this.pageName, Body: JSON.stringify(filter) });
    let url = this.baseUrl + "/CrmData/GetCrmDataV2";
    return this.http
      .post(url, body, options)
      .pipe(
        map((res: any) => {
          pagedData.data = res.data;
          page.totalElements = res.total;
          page.totalPages = page.totalElements < page.size ? 1 : page.totalElements / page.size;
          pagedData.page.totalElements = res.total;
          pagedData.page.totalPages = page.totalElements < page.size ? 1 : page.totalElements / page.size;
          pagedData.page = page;
          pagedData.additionalData = res.additionalData;
          return pagedData;
        })
      )
      .toPromise();
  }

  public GetBankInfoByContactId(contactId: number) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    return this.http
      .get(this.baseUrl + "/CrmData/GetBankInfoByContactId?contactId=" + contactId, options)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .toPromise();
  }

  public GetSysConfig(configCode: string) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    return this.http
      .get(this.baseUrl + "/VVSFrontend/GetVssSysConfig?configCode=" + configCode, options)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .toPromise();
  }
  public GetCrmDataByKeyword(filter: any) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    const body = JSON.stringify({ PageName: this.pageName, Body: JSON.stringify(filter) });
    return this.http
      .post(this.baseUrl + "/CrmData/GetCrmDataByKeyword", body, options)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .toPromise();
  }
  public InsertItem(bodyData: any) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    const body = JSON.stringify({ PageName: this.pageName, Body: JSON.stringify(bodyData) });
    return this.http
      .post(this.baseUrl + "/CrmData/InsertCrmData", body, options)
      .catch(this.handleError)
      .toPromise();
  }

  public InsertTopupManagement(bodyData: any) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    const body = JSON.stringify({ PageName: this.pageName, Body: JSON.stringify(bodyData) });
    return this.http
      .post(this.baseUrl + "/CrmData/InsertCrmDataV2", body, options)
      .catch(this.handleError)
      .toPromise();
  }

  public UpdateItem(bodyData: any) {
    bodyData.userInfo = this.userInfo;
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };

    const body = JSON.stringify({ PageName: this.pageName, Body: JSON.stringify(bodyData) });
    return this.http
      .post(this.baseUrl + "/CrmData/UpdateCrmData", body, options)
      .catch(this.handleError)
      .toPromise();
  }

  public exportPDF(filter) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    const body = JSON.stringify({ PageName: this.pageName, Body: JSON.stringify(filter) });
    return this.http
      .post(this.baseUrl + `/CrmData/ExportPDF`, body, options)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .toPromise();
  }

  public updateBookingPaid(model, guid) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };

    // --- Binh Nguyen add ---
    if (typeof model === "object") {
      model.LastModifiedBy = this.userInfo.fullname;
    }
    // --- /.Binh Nguyen add ---

    return this.http
      .post(this.baseUrl + `/CrmData/UpdateBookingPaid?guid=${guid}`, model, options)
      .catch(this.handleError)
      .toPromise();
  }

  public UpdateBookingPIC(bookingId, pic) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    return this.http
      .post(this.baseUrl + `/CrmData/UpdateBookingPIC?bookingId=${bookingId}&pic=${pic}`, null, options)
      .catch(this.handleError)
      .toPromise();
  }

  public updateBooking(model, guid) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };

    // --- Binh Nguyen add ---
    if (typeof model === "object") {
      model.LastModifiedBy = this.userInfo.fullname;
    }
    // --- /.Binh Nguyen add ---

    return this.http
      .post(this.baseUrl + `/CrmData/UpdateBooking?guid=${guid}`, model, options)
      .catch(this.handleError)
      .toPromise();
  }

  public updateBookingHotel(model, guid) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };

    // --- Binh Nguyen add fix temp cho nhanh ---
    if (typeof model === "object") {
      model.LastModifiedBy = this.userInfo.fullname;
    }
    // --- /.Binh Nguyen add fix temp cho nhanh ---

    return this.http
      .post(this.baseUrl + `/CrmData/UpdateBookingHotel?guid=${guid}`, model, options)
      .catch(this.handleError)
      .toPromise();
  }
  // get money account
  public getMoneyAccount(acount: number) {
    let token = this.AuthService.getToken();
    let options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + token,
      }),
    };
    return this.http.get(this.baseUrl + "/CrmData/CheckMoneyAccount?IdAccount=" + acount, options).toPromise();
  }
  public getAccountContactId(acount: number) {
    let token = this.AuthService.getToken();
    let options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + token,
      }),
    };
    return this.http.get(this.baseUrl + "/CrmData/GetContactByID?id=" + acount, options).toPromise();
  }
  public UpdateStatusFlight(status, bookingCode, codeOld, codeNew) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    return this.http
      .post(this.baseUrl + `/CrmData/UpdateStatusFlight?status=${status}&bookingCode=${bookingCode}&codeOld=${codeOld}&codeNew=${codeNew}`, options)
      .catch(this.handleError)
      .toPromise();
  }
  public paymentFlight(model) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    return this.http
      .post(this.baseUrl + `/CrmData/PaymentFlight`, model, options)
      .catch(this.handleError)
      .toPromise();
  }
  public SaveLogTicket(model) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    return this.http
      .post(this.baseUrl + `/CrmData/SaveLogTicket`, model, options)
      .catch(this.handleError)
      .toPromise();
  }
  public PlutosSyncBooking(resId, prn, directionFlight) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    return this.http
      .post(this.baseUrl + `/CrmData/PlutosSyncBooking?resId=${resId}&PlutosSyncBooking=${prn}&directionFlight=${directionFlight}`, options)
      .catch(this.handleError)
      .toPromise();
  }
  public UpdateStatusBooking(bookingId) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    return this.http
      .post(this.baseUrl + `/CrmData/UpdateStatusBooking?bookingId=${bookingId}`, options)
      .catch(this.handleError)
      .toPromise();
  }
  public UpdateStatusBookingAndNotify(model) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    return this.http
      .post(this.baseUrl + `/CrmData/UpdateStatusBookingAndNotify`, model, options)
      .catch(this.handleError)
      .toPromise();
  }
  public GetBookingDetailByBookingID_CRM(BookingId, SupplierCode) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    return this.http
      .get(this.baseUrl + `/CrmData/GetBookingDetailByBookingID_CRM?BookingId=${BookingId}&SupplierCode=${SupplierCode}`, options)
      .catch(this.handleError)
      .toPromise();
  }
  public UpdateSmsLogs(ID: number, CreateBy: string) {
    let token = this.AuthService.getToken();
    let options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + token,
      }),
    };
    return this.http
      .post(this.baseUrl + `/CrmData/UpdateSmsLogs?ID=${ID}&CreateBy=${CreateBy}`, options)
      .catch(this.handleError)
      .toPromise();
  }
  public InsertBookingOffInternal(model) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    return this.http
      .post(this.baseUrl + `/CrmData/InsertBookingOffInternal`, model, options)
      .catch(this.handleError)
      .toPromise();
  }

  public createNewPNR(listProduct, model) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };

    // --- Binh Nguyen add ---
    if (typeof model === "object") {
      model.LastModifiedBy = this.userInfo.fullname;
    }
    // --- /.Binh Nguyen add ---

    return this.http
      .post(this.baseUrl + `/CrmData/CreateNewPNR`, { ListProducts: listProduct, BookingsModel: model }, options)
      .catch(this.handleError)
      .toPromise();
  }
  public GetUserAdmin(): Promise<string[]> {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
  
    return this.http
      .get<string[]>(this.baseUrl + `/CrmData/GetUserAdminCRM`, options)
      .catch(this.handleError)
      .toPromise();
  }
  
  public GetUserAdminCRMMenu() {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    return this.http
      .get(this.baseUrl + `/CrmData/GetUserAdminCRMMenu`, options)
      .catch(this.handleError)
      .toPromise();
  }

  public GetUserAccounting() {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    return this.http
      .get(this.baseUrl + `/CrmData/GetUserAccountingCRM`, options)
      .catch(this.handleError)
      .toPromise();
  }

  public getDataLog(productElementId: number, entityType: number) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };

    const pagedData = new PagedData<any>();
    return this.http
      .get(`${this.baseUrl}/CrmData/GetCrmDataLogBooking?productElementId=${productElementId}&entityType=${entityType}`, options)
      .pipe(
        map((res: any) => {
          pagedData.data = res.data;
          return pagedData;
        }),
        catchError(this.handleError)
      )
      .toPromise();
  }

  public getRevenue(filter: any) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    const body = JSON.stringify({ PageName: this.pageName, Body: JSON.stringify(filter) });
    return this.http
      .post(this.baseUrl + "/CrmData/GetCrmDataByKeyword", body, options)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .toPromise();
  }

  public getFirstActive(filter: any) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    const body = JSON.stringify({ PageName: this.pageName, Body: JSON.stringify(filter) });
    return this.http
      .post(this.baseUrl + "/CrmData/GetCrmDataByKeyword", body, options)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .toPromise();
  }
  public getContactIdByPhoneOrEmail(key: string) {
    let token = this.AuthService.getToken();
    let options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + token,
      }),
    };
    return this.http.get(this.baseUrl + "/CrmData/GetContactIdByPhoneOrEmail?key=" + key, options).toPromise();
  }
  public getXMLHotel(key: string) {
    let token = this.AuthService.getToken();
    let options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + token,
      }),
    };
    return this.http.get(this.baseUrl + "/CrmData/GetXMLHotel?transactionId=" + key, {}).toPromise();
  }
  public CreateBooking(itemBooking: any) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    const body = JSON.stringify({ PageName: "", Body: JSON.stringify(itemBooking) });
    return this.http
      .post(this.baseUrl + "/CrmData/CreateBookingFromCRM", body, options)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .toPromise();
  }

  public SendMailConfirmTicKet(model: any) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };

    const body = JSON.stringify({ Body: JSON.stringify(model) });
    return this.http
      .post(this.baseUrl + "/VVSFrontend/UpdateTicketIssued", model, options)
      .catch(this.handleError)
      .toPromise();
  }
  public GetHotelByName(key: string) {
    let token = this.AuthService.getToken();
    let options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + token,
      }),
    };
    return this.http.get(this.baseUrl + "/CrmData/GetHotelByName?nameHotel=" + key, {}).toPromise();
  }
  public GetHotelByWotifCode(key: string) {
    let token = this.AuthService.getToken();
    let options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + token,
      }),
    };
    return this.http.get(this.baseUrl + "/CrmData/GetHotelByWotifCode?WotifCode=" + key, {}).toPromise();
  }
  public GetAccountPocketsByAccountId(accountId: number) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    return this.http
      .get(this.baseUrl + "/CrmData/GetAccountPocketsByAccountId?accountId=" + accountId, options)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .toPromise();
  }

  public GetBankInfoById(id: number) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    return this.http
      .get(this.baseUrl + "/CrmData/GetBankInfoById?id=" + id, options)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .toPromise();
  }

  public GetListDeviceTokenByUser(loginUser: string) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    return this.http
      .get(this.baseUrl + "/VVSFrontend/GetListDeviceTokenByUser?loginUser=" + loginUser, options)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .toPromise();
  }

  public InsertMemberNotificationOlivia(bodyData: any) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    return this.http
      .post(this.baseUrl + "/VVSFrontend/InsertMemberNotificationOlivia", bodyData, options)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .toPromise();
  }

  public InsertNotification(bodyData: any) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    const body = JSON.stringify({ PageName: this.pageName, Body: JSON.stringify(bodyData) });

    return this.http
      .post(this.baseUrl + "/CrmData/InsertNotification", body, options)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .toPromise();
  }

  public exportAllAG(filter: any) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    const body = JSON.stringify({ PageName: this.pageName, Body: JSON.stringify(filter) });
    return this.http
      .post(this.baseUrl + "/CrmData/ExportPDF", body, options)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .toPromise();
  }

  public GetHotelDetail(woftiCode: string) {
    return this.http
      .get(`${this.baseUrl}/VVSFrontendWeb/GetHotelDetail?woftiCode=${woftiCode}`)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .toPromise();
  }

  async GetCrmDataByKeywordV2(filter: any): Promise<any> {
    const headers = new HttpHeaders({
      Accept: "application/json",
      "Content-Type": "application/json-patch+json",
      Authorization: `Bearer ${this.AuthService.getToken()}`,
    });

    const body = JSON.stringify({ PageName: this.pageName, Body: JSON.stringify(filter) });

    try {
      const response = await this.http.post<any>(`${this.baseUrl}/CrmData/GetCrmDataByKeywordV2`, body, { headers }).toPromise();
      return response;
    } catch (error) {
      console.error("An error occurred:", error);
      throw error;
    }
  }

  InsertNoteToSup(filter: FilterInterface) {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json-patch+json",
        Authorization: "Bearer " + this.AuthService.getToken(),
      }),
    };
    const body = JSON.stringify({ PageName: this.pageName, Body: JSON.stringify(filter) });

    return this.http
      .post(this.baseUrl + "/CrmData/InsertCrmDataV2", body, options)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .toPromise();
  }

  /**
   * Lấy danh sách MST dựa trên thông tin người dùng (Email | Member Id)
   * @returns 
   */
  public async GetTaxCode(memberId: string): Promise<ResponseApi> {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${this.AuthService.getToken()}`,
      }),
    };

    const body = {
      email: this.uService.userInfo.email,
      GuidId: memberId
    };

    try {
      const response = await this.http.post<ResponseApi>(`${this.baseUrl}/VVSFrontend/GetTaxCode`, body, options)
        .pipe(
          map((res): ResponseApi => {
            return res;
          }),
          catchError(this.handleError)
        )
        .toPromise();
      return response;
    } catch (error) {
      throw error;
    }
  }

  /**
   * search MST nếu như chưa tồn tại trong dữ liệu người dùng
   * @param taxCode 
   * @returns 
   */
  public async SearchTaxCodeVietQR(taxCode: string): Promise<ResponseApi> {
    const options = {
      headers: new HttpHeaders({
        accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${this.AuthService.getToken()}`,
      }),
    };

    const body = {
      taxCode: taxCode,
    };

    try {
      const response = await this.http.post<ResponseApi>(`${this.baseUrl}/VVSFrontend/SearchTaxCodeVietQR`, body, options)
        .pipe(
          map((res): ResponseApi => {
            return res;
          }),
          catchError(this.handleError)
        )
        .toPromise();
      return response;
    } catch (error) {
      throw error;
    }
  }
}
