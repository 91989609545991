export interface UserInterface {
  emailOrPhone: string;
  password: string;
}
export class UserInfoModel {
  memberId: string;
  email: string;
  fullname: string;
  gender: number;
  phone: string;
  avatar: string;
  avatarFile: File;
  passCheckbox: boolean;
  otpPhone: string;
  oldPassword: string;
  newPassword1: string;
  newPassword2: string;
  shortname: string;
  point: number; 
  refreshToken: string;
  refreshTokenTimer: string;
  pickupType: number;
  address: string;
  itemId: number;
  itemType: number;
  temp: string;
  tcincharrge: number;
  username: string;
}