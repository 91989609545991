import { Component, OnInit, Inject } from '@angular/core';
// import { MatSpinner } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-process-spinner',
  templateUrl: './process-spinner.component.html',
  styleUrls: ['./process-spinner.component.scss']
})
export class ProcessSpinnerComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ProcessSpinnerComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }
  ngOnInit() { }
}
