import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
  name: "PipeFormatCurrcency",
})
export class PipeFormaCurrency implements PipeTransform {
  /**
   * 
   * @param value giá trị cần format
   * @param currencyCode đơn vị tiền tệ dựa theo 
   * @param locale code quốc gia
   * @returns 
   */
  transform(value: number, currencyCode: string = "USD", locale: string = "en-US"): string {
    if (typeof value !== "number") {
      return "";
    }

    return new Intl.NumberFormat(locale).format(value);
  }
}
