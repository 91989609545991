import { Component ,NgZone} from '@angular/core';
import { UserInterface, UserInfoModel } from '../../../shared/models/user.interface'; 
import { UserService } from '../../../shared/services/user.service'; 
import { AppAuthService } from '../../../shared/services/roleService/auth.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {
  userInfo = new UserInfoModel();
  isLogged: boolean;
  constructor(
    private userService: UserService, private zone: NgZone,   private authService: AppAuthService
    ) {} 
  ngOnInit(){ 
    this.checkLogin();
  }
  logout(){
    this.userService.logout();
    
  }
  countdownRefreshToken() {
    var timer = parseInt(this.userInfo.refreshTokenTimer);
    this.countdownTimer(timer);
}

countdownTimer(timer: number) {
    if (timer >= 0) {

        this.zone.runOutsideAngular(() =>
        {
            var interval = setInterval(() => {
                //console.log(timer);
                timer--;

                if (timer < 0) {
                    clearInterval(interval);
                    // Reload lại Token để lấy User Info mới nhất
                    this.userService.reloadToken()
                    //.takeUntil(this.onDestroy$)
                        .subscribe(
                            result => {
                                //console.log("Reload user info thành công !");
                                // Tạo 1 vòng lặp refresh liên tục khi get Token mới
                                this.userService.getUserInfo();
                                var timer = parseInt(this.userInfo.refreshTokenTimer);
                                this.countdownTimer(timer);

                            },
                            error => { });
                }
            }, 1000);
        });
    }
}
checkLogin() {
  this.isLogged = this.authService.isAuthenticated();
  if (this.isLogged) {
      this.userInfo = this.userService.getUserInfo();
      this.countdownRefreshToken();
  }
}
}
