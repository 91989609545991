import { Injectable } from "@angular/core";

@Injectable()
export class UtilityService {
  b64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 512;

    //decode base64 data
    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  dateDiff(startDate: any, endDate: any) {
    var start = new Date(startDate);
    var end = new Date(endDate);
    let result = start.getTime() - end.getTime();
    return result;
  }
  change_money(event) {
    // let formatNumber = event.target.value.replace(/\,/g, ''); // Binh Nguyen delete
    // --- Binh Nguyen Modify ---
    const rx = /\D/g;
    let formatNumber = event.target.value.replace(rx, '');
    // --- Binh Nguyen Modify ---

    event.target.value = this.numberToCurrency(formatNumber, ',');
    //console.log('change_money', this.numberToCurrency(formatNumber, ','));
  }
  numberToCurrencyVND(event,number: number, thousands: string) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    else
    {
      if (number == undefined) return "undefined";
      var parts = this.round(number, 0).toString().split(",");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands);
      console.log('hiển thị số: ',parts.join(thousands));
      return parts.join(thousands);
    }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  round(number, precision) {
    var factor = Math.pow(10, precision);
    var tempNumber = number * factor;
    var roundedTempNumber = Math.round(tempNumber);
    return roundedTempNumber / factor;
  }
  numberToCurrency(number: number, thousands: string) {
    if (number == undefined) return "undefined";
    var parts = this.round(number, 0).toString().split(",");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands);
    return parts.join(thousands);
  }
  numberToCurrency500(number: number, thousands: string) {
    try {
      let No3Digits = number % 1000;
      if (No3Digits == 0) {
        No3Digits = No3Digits;
      }
      else if (No3Digits == 500) {
        No3Digits = No3Digits;
      }
      else if (No3Digits > 500) {
        No3Digits = 1000;
      }
      else if (No3Digits < 500) {
        No3Digits = 500;
      }
      number = Math.trunc(number / 1000) * 1000 + No3Digits;
      if (number == undefined) return "undefined";
      var parts = this.round(number, 0).toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands);
      return parts.join(thousands);
    } catch (error) {
      return "0";
    }

  }
}