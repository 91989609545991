import { Routes } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { RoleGuardService } from './shared/services/roleService/role.guard';
import { LoginComponent } from './login/login.component';
import { StarterComponent } from './starter/starter.component';
export const AppRoutes: Routes = [
  {
    path: 'login', data: { useTemplate: false }, children: [{ path: '', component: LoginComponent }]
  },
  // { path: 'starter', component: StarterComponent, canActivate: [RoleGuardService], data: { roles: ['Admin'] } },
  {
    path: '',
    component: FullComponent,
    canActivate: [RoleGuardService], data: { roles: ['Admin', 'GiaoHangFood', 'NhapLieuFood', 'BookingFood', 'DiChoFood'] },
    children: [
      {
        path: '',
        redirectTo: '/bookings',
        pathMatch: 'full'
      },
      {
        path: '',
        loadChildren: './main-component/main.module#MainComponentsModule'
      },
      {
        path: 'dashboard',
        redirectTo: '/dashboardV2',
        pathMatch: 'full'
      },
      {
        path: 'bookings',
        redirectTo: '/bookings',
        pathMatch: 'full'
      },
      {
        path: 'bookingwithluggages',
        redirectTo: '/bookingwithluggages',
        pathMatch: 'full'
      },
      {
        path: 'bookforagent',
        redirectTo: '/bookforagent',
        pathMatch: 'full'
      },
      {
        path: 'accounts',
        redirectTo: '/accounts',
        pathMatch: 'full'
      },
      {
        path: 'accounting',
        redirectTo: '/accounting',
        pathMatch: 'full'
      },
      {
        path: 'contacts',
        redirectTo: '/contacts',
        pathMatch: 'full'
      },
      {
        path: 'campaigns',
        redirectTo: '/campaigns',
        pathMatch: 'full'
      },
      {
        path: 'activities',
        redirectTo: '/activities',
        pathMatch: 'full'
      },
      {
        path: 'bookingofflight',
        redirectTo: '/bookingofflight',
        pathMatch: 'full'
      },
      {
        path: 'products',
        redirectTo: '/products',
        pathMatch: 'full'
      },
      {
        path: 'starter',
        loadChildren: './starter/starter.module#StarterModule'
      },
      {
        path: 'issueinvoice',
        redirectTo: '/issueinvoice',
        pathMatch: 'full'
      },
      {
        path: 'bookingshotel',
        redirectTo: '/bookingshotel',
        pathMatch: 'full'
      },
      {
        path: 'pushnotification',
        redirectTo: '/pushnotification',
        pathMatch: 'full'
      },
      {
        path: 'bookingsmanagement',
        redirectTo: '/bookingsmanagement',
        pathMatch: 'full'
      },
      {
        path: '*',
        redirectTo: '/bookings',
        pathMatch: 'full'
      },
      {
        path: 'followinvoice',
        redirectTo: '/followinvoice',
        pathMatch: 'full'
      },
    ]
  },
];

