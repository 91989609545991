import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/internal/Rx';
import { map, catchError } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import * as decode from 'jwt-decode';
import { Route, Router } from '@angular/router';
import { UserInterface, UserInfoModel } from '../models/user.interface';
import { ConfigService } from '../utils/config.service';
import { AppAuthService } from './roleService/auth.service';
import { BaseService } from './base.service';
import { environment } from '../../../environments/environment';
import jwt_decode from 'jwt-decode';
import { helpers } from 'chart.js';

@Injectable()
export class UserService extends BaseService {

  baseUrl: string = '';
  avatarFolderUrl: string = '';
  private isLogged = false;
  userInfo = new UserInfoModel();
  breadcumbTitle: string;
  tempBackground: string;

  // detect User log
  public _watchUserLog = new BehaviorSubject<boolean>(false);
  urlPlutos: string;
  constructor(
    private configService: ConfigService,
    private titleService: Title,
    private authService: AppAuthService,
    private http: HttpClient, public router: Router
  ) {
    super();
    this.isLogged = this.authService.isAuthenticated();
    this.emitUserLog(this.isLogged);
    this.baseUrl = configService.getLoginUrl();
    this.urlPlutos = configService.getApiURI();
    this.avatarFolderUrl = this.configService.getAvartarFolder();
  }

  // phát tín hiệu đến các Component
  emitUserLog(isLogin) {
    this._watchUserLog.next(isLogin);
  }

  setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
    this.breadcumbTitle = newTitle;
  }

  register(email, password, password2) {
    let body = JSON.stringify({ email, password, password2 });
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<any>(this.baseUrl + "/api/account/register", body, options)
      .pipe(map(res => {
        return true;

      }), catchError(this.handleError));
  }

  login(emailOrPhone, password) {
    let body = JSON.stringify({ emailOrPhone, password });
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http
      .post(
        this.baseUrl + '/api/Account/login', body, options)
      .pipe(map((result: any) => {
        this.authService.setToken(result.auth_token);
        let decode = jwt_decode(result.auth_token);
        localStorage.setItem("memberId", decode.jti);
        localStorage.setItem("password", password);
        localStorage.setItem("email", decode.email);
        localStorage.setItem("fullname", decode.fullname);
        console.log(decode);
        this.isLogged = true;
        this.emitUserLog(this.isLogged);
        // this.onRefresh();
        return true;
      }));
  }

  logout() {
    let token = this.authService.getToken();
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + token
      })
    };

    return this.http
      .get(
        this.baseUrl + '/api/account/Logout', options)
      .pipe(map(res => {
        // Xóa cookie
        localStorage.removeItem("memberId");
        localStorage.removeItem("password");
        localStorage.removeItem("email");
        localStorage.removeItem("loginUser");
        this.authService.logout();
        this.isLogged = false;
        this.emitUserLog(this.isLogged);
        this.router.navigate(['/login']);
        window.location.reload();
      }), catchError(this.handleError)).subscribe();
  }


  onRefresh() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };

    let currentUrl = this.router.url + '?';

    this.router.navigateByUrl(currentUrl)
      .then(() => {
        this.router.navigated = false;
        this.router.navigate([this.router.url]);
      });
  }
  // Nếu Refresh Token hết hạn thì get lại Token Chính
  // Nếu Refresh Token ko đúng với Server thì logout 
  reloadToken() {

    //console.log("Reload user info...");
    let token = this.authService.getToken();
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + token
      })
    };

    return this.http
      .get(
        this.baseUrl + '/account/reloadTokenClaims', options)
      .pipe(map(res => {
        var result: any = res;
        this.authService.setToken(result.auth_token);
        this.getUserInfo();

      }), catchError(this.handleError));
  }

  checkAvatarURL(avatarUrl: string): string {
    // Nếu là link avatar Google, Facebook
    if (avatarUrl != null && avatarUrl != "") {
      if (avatarUrl.startsWith("http://") || avatarUrl.startsWith("https://"))
        return avatarUrl;
      else
        return this.avatarFolderUrl + "/" + avatarUrl;
    }
    else
      return avatarUrl;
  }

  getShortName(fullname: string) {
    if (fullname != null) {
      // Nếu tên là email thì hiện chữ trước @
      if (fullname.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/g))
        return fullname.split('@')[0];
      // Nếu tên là fullname thì hiện Tên chính
      else
        return fullname.split(" ").pop().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
    }
  }
  bookingOff(pageIndex) {
    let token = this.authService.getToken();
    let queryParams = new HttpParams();
    queryParams = queryParams.append("getall", "true");
    queryParams = queryParams.append("getHistory", "true");
    queryParams = queryParams.append("pageIndex", pageIndex);
    queryParams = queryParams.append("pageSize", "100");
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + token
      }),
      params: queryParams
    };
    return this.http.get(this.baseUrl + '/api/dashboard/getMyTripPaging', options).pipe(map(res => {
      console.log('bkg off', res);
    }), catchError(this.handleError)).subscribe();
  }
  getBookingDetailByCode(_bookingCode) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "apiKey": "0HY9qKyvwty1hSzcTydn0AHAXPb0e2QzYQlMuQowS8U",
        "apiSecret": "2Vg_RTAccmT1mb1NaiirtyY2Y3OHaqUfQ6zU_8gD8SU",
      }),
    };
    return this.http.post(this.baseUrl + `/app/CRMOldApis/getBookingDetailByCode?bookingCode=${_bookingCode}`, {}, options).pipe(map((res: any) => {
      return res;
    })).toPromise();
  }
  findBookingCodeFromIvivu(_bookingCode) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "apiKey": "0HY9qKyvwty1hSzcTydn0AHAXPb0e2QzYQlMuQowS8U",
        "apiSecret": "2Vg_RTAccmT1mb1NaiirtyY2Y3OHaqUfQ6zU_8gD8SU",
      }),
    };
    return this.http.get(this.urlPlutos + `/CrmData/FindBookingCodeFromIvivu?bookingCode=${_bookingCode}`, {}).pipe(map((res: any) => {
      return res;
    })).toPromise();
  }
  getUserInfo() {
    let token = this.authService.getToken();
    if (token && token != null && token.length > 0) {
      let decodeToken = decode(token);
      if (decodeToken.jti.length <= 2)
        this.userInfo.memberId = decodeToken.jti[0];
      else
        this.userInfo.memberId = decodeToken.jti;
      this.userInfo.email = decodeToken.email;
      this.userInfo.fullname = decodeToken.fullname;
      this.userInfo.avatar = this.checkAvatarURL(decodeToken.avatar);
      this.userInfo.phone = decodeToken.phone;
      this.userInfo.shortname = this.getShortName(decodeToken.fullname);
      this.userInfo.point = decodeToken.point;
      this.userInfo.refreshToken = decodeToken.refreshToken;
      this.userInfo.refreshTokenTimer = decodeToken.refreshTokenTimer
      this.userInfo.gender = decodeToken.gender == null ? 0 : (decodeToken.gender == 'F' ? 2 : 1);
      this.userInfo.username = decodeToken.username;
      if (!environment.production) console.log(decodeToken);
      return this.userInfo
    } else {
      return null
    }
  }

}